import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决多次点击同个路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/home',
    name: 'Home',
    props: {
      navname: '首页'
    },
    component: () => import('@/views/home.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () =>
          import(/*webpackChunkName:"Home"*/ '@/views/Home/Index.vue')
      },
      {
        path: '/keyan',
        name: 'keyan',
        component: () =>
          import(/*webpackChunkName:"keyan"*/ '@/views/Home/keyan.vue')
      },
      {
        path: '/substitution',
        name: 'substitution',
        component: () =>
          import(
            /*webpackChunkName:"substitution"*/ '@/views/Home/substitution.vue'
          )
      },
      {
        path: '/about',
        name: 'about',
        component: () =>
          import(/*webpackChunkName:"about"*/ '@/views/about/about.vue')
      },
      {
        path: '/hire',
        name: 'hire',
        component: () =>
          import(/*webpackChunkName:"hire"*/ '@/views/about/hire.vue')
      },
      {
        path: '/detail/:id',
        name: 'detail',
        component: () =>
          import(/*webpackChunkName:"detail"*/ '@/views/about/detail.vue')
      },

      {
        path: '/parentPage/:name/:type',
        name: 'parentPage',
        component: () =>
          import(
            /*webpackChunkName:"parentPage"*/ '@/views/item/parentPage.vue'
          )
      },
      {
        path: '/community',
        name: 'community',
        component: () =>
          import(/*webpackChunkName:"community"*/ '@/views/item/community.vue')
      },
      {
        path: '/subPage/:typeId/:id',
        name: 'subPage',
        component: () =>
          import(/*webpackChunkName:"subPage"*/ '@/views/item/subPage.vue')
      },
      // 详情页
      {
        path: '/details/:name/:typeId/:id',
        name: '详情页',
        component: () =>
          import(/*webpackChunkName:"details"*/ '@/views/item/details.vue')
      },
      {
        path: '/customService',
        name: 'customService',
        component: () =>
          import(
            /*webpackChunkName:"customService"*/ '@/views/customService/index.vue'
          )
      },
      {
        path: '/contact',
        name: 'contact',
        component: () =>
          import(/*webpackChunkName:"contact"*/ '@/views/contact/index.vue')
      },

      // 更多案例分类
      {
        path: '/moreKinds/:id',
        name: 'moreKinds',
        component: () =>
          import(
            /*webpackChunkName:"moreKinds"*/ '@/views/customService/moreKinds.vue'
          )
      },
      {
        path: '/programs/:id?',
        name: 'programs',
        component: () =>
          import(
            /*webpackChunkName:"programs"*/ '@/views/customService/programDetail.vue'
          )
      },
      // 分类案例详情
      {
        path: '/categoryCaseDetails/:name/:typeId/:id',
        name: 'categoryCaseDetails',
        component: () =>
          import(
            /*webpackChunkName:"categoryCaseDetails"*/ '@/views/customService/categoryCaseDetails.vue'
          )
      },
      // 更多解决方案
      {
        path: '/moreSolutions',
        name: 'moreSolutions',
        component: () =>
          import(
            /*webpackChunkName:"moreSolutions"*/ '@/views/customService/moreSolutions.vue'
          )
      },

      // 解决方案详情
      {
        path: '/schemeDetails/:id',
        name: 'schemeDetails',
        component: () =>
          import(
            /*webpackChunkName:"schemeDetails"*/ '@/views/customService/schemeDetails.vue'
          )
      },
      // 客户案例详情
      {
        path: '/customerCaseDetails/:id',
        name: 'customerCaseDetails',
        component: () =>
          import(
            /*webpackChunkName:"customerCaseDetails"*/ '@/views/customService/customerCaseDetails.vue'
          )
      },
      {
        path: '/project',
        name: 'project',
        component: () =>
          import(/*webpackChunkName:"project"*/ '@/views/project/index.vue')
      },
      {
        path: '/project/:name/:typeId/:id',
        name: 'project',
        component: () =>
          import(/*webpackChunkName:"project"*/ '@/views/project/project.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
// 跳转页面 回到顶部
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next()
})

export default router
