import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import AOS from 'aos'
import '../node_modules/aos/dist/aos.css'

import './assets/font/OPPOSans_B.css'
import './assets/font/OPPOSans_L.css'
import './assets/font/OPPOSans_H.css'
import './assets/font/OPPOSans_M.css'
import './assets/font/OPPOSans_R.css'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.use(AOS)
Vue.use(ElementUI, {
  size: 'small',
  zIndex: 3000
})

Vue.directive('drag', {
  // 钩子函数，被绑定元素插入父节点时调用 (父节点存在即可调用，不必存在于 document 中)。
  inserted: (el, binding, vnode, oldVnode) => {
    let drag = el // 要拖拽的元素
    let dragImg = document.createElement('span') // 拖拽图标
    let X = 0

    drag.draggable = 'true' // 使元素可直接拖拽
    drag.style.width = 'max-content' // 使元素充满容器
    drag.appendChild(dragImg) // 添加拖拽图标，不添加则默认显示拖拽元素

    // 给元素添加父元素
    let wrapper = document.createElement('div') //  新建父元素
    wrapper.className = 'scroll-middle' // 这个类是自定义的滚动条类
    wrapper.style.overflowX = 'hidden'
    wrapper.style.overflowY = 'hidden'

    // 将父元素添加进去
    drag.parentNode.replaceChild(wrapper, drag) //  获取子元素原来的父元素并将新父元素代替子元素
    wrapper.appendChild(drag) //  在新父元素下添加原来的子元素

    drag.ondragstart = function (e) {
      e = e || window.event
      X = e.offsetX
      e.dataTransfer.setDragImage(dragImg, 0, 0)
    }
    drag.ondrag = function (e) {
      if (drag.clientWidth > wrapper.clientWidth) {
        e = e || window.event
        if (
          0 <= wrapper.scrollLeft &&
          wrapper.scrollLeft <= wrapper.scrollWidth &&
          e.clientX != 0
        ) {
          wrapper.scrollLeft = wrapper.scrollLeft + (X - e.offsetX)
        }
      }
    }
    // drag.ondragend = function (e) {
    //   console.log("拖拽结束", e);
    // };
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
