<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import AOS from 'aos'
export default {
  mounted() {
    AOS.init({
      offset: 200,
      duration: 600, //持续时间
      easing: 'ease-in-sine',
      delay: 100
    })
  }
}
</script>

<style lang="less">
/* @import url("../src/assets/css/reset.css"); */
@import url('../src/assets/css/common.css');
@import url('../src/assets/css/reset.css');
@import url('../src/assets/iconfont/iconfont.css');

body {
  margin: 0px;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

html,
body,
#app {
  scrollbar-width: none;
  caret-color: rgba(0, 0, 0, 0) !important;
  font-family: 'OPPOSans-M';
}

a {
  text-decoration: none;
  border: 0;
  outline: none;
}
span {
  display: inline-block;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
